/* projects.store.js */

// State object
const state = {
    all_projects: [],
}

// Getter functions
const getters = {
    getProjectById: (state) => (id) => {
        return state.all_projects.find(project => project.id === id)
    },

    getDeploProjects: (state) => {
        return state.all_projects.filter(project => project.is_deplo)
    }
}

// Actions
const actions = {
    fetchProjects({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios.get("/api/projects")
                .then((response) => {
                    commit('SET_PROJECTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchProjectDetails({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.id}?${payload.params}`)
                .then((response) => {
                    //commit('UPDATE_OR_SET_PROJECTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchProjectLogs({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.project_id}/logs?${payload.params}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchIncidentDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/api/projects/${payload.project_id}/incidents/${payload.incident_id}?include=events`)
          .then((response) => {
            //commit('UPDATE_OR_SET_PACKAGES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateIncident({commit}, payload) {
      return new Promise((resolve, reject) => {
        this.$axios.patch(`/api/projects/${payload.project_id}/incidents/${payload.incident_id}`, payload.formData)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error.response.data.message)
          })
      })
    },
    createProject({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.post("/api/projects", formData)
                .then((response) => {
                    commit('CREATE_PROJECT', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updateProject({commit}, formData) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/projects/${formData.id}`, formData.payload)
                .then((response) => {
                    commit('UPDATE_OR_SET_PROJECTS', response.data.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    deleteProject({commit}, id) {
        return new Promise((resolve, reject) => {
            this.$axios.delete("/api/projects/" + id)
                .then((response) => {
                    commit('DELETE_PROJECT', id)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchProjectPermissions({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.get(`/api/projects/${payload.id}/users`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addUserToProject({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.post(`/api/projects/${payload.project_id}/users`, payload.data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    removeUserFromProject({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.delete(`/api/projects/${payload.project_id}/users/${payload.user_id}`)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.message)
                })
        })
    },
    updateProjectUserRole({commit}, payload) {
        return new Promise((resolve, reject) => {
            this.$axios.patch(`/api/projects/${payload.project_id}/users/${payload.user_id}`, payload.data)
                .then((response) => {
                    //commit('UPDATE_USER', response.data.id, response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}

// Mutations
const mutations = {
    SET_PROJECTS(state, projects) {
        state.all_projects = projects
    },
    UPDATE_OR_SET_PROJECTS(state, current_project) {
        let item = state.all_projects.find(project => project.id === current_project.id)
        if(item) {
            Object.assign(item, current_project)
        }
        else {
            state.all_projects.unshift(current_project) // Add project to all_projects
        }
    },
    CREATE_PROJECT(state, project) {
        state.all_projects.push(project)
    },
    DELETE_PROJECT(state, id) {
        let index = state.all_projects.findIndex(proj => proj.id === id)
        state.all_projects.splice(index, 1)

    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
