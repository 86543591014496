<template>

  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>

    <div class="tile is-ancestor is-vertical">
      <div class="tile is-parent">
        <div class="card tile is-child">
          <b-loading :is-full-page="false" :active.sync="!this.isLoaded" :can-cancel="false"></b-loading>

          <b-tabs v-if="this.isLoaded" v-model="activeTab">
            <b-tab-item v-for="the_package in packages" :label="the_package.name"  icon="lock-clock">

              <b-table
                  :data="the_package.outputs"

              >
                <b-table-column field="local_id" label="Bus" v-slot="props">
                  {{props.row.local_id}}
                </b-table-column>

                <b-table-column field="internal_name" label="Internal name" v-slot="props">
                 {{props.row.internal_name}}
                </b-table-column>

                <b-table-column field="name" label="Visible name" v-slot="props">
                  <b-icon class="pr-3 " type="is-primary is-light" :icon="props.row.icon"></b-icon>
                  <b>{{props.row.name}}</b>
                </b-table-column>

                <b-table-column field="output" label="Status" v-slot="props">
                  <b-switch disabled  :true-value="1" :false-value="0" v-model="props.row.output" :left-label="true">
                    {{ props.row.output ? 'Active' : 'Off' }}
                  </b-switch>
                </b-table-column>

                <b-table-column field="used" label="In Use" v-slot="props">
                  <b-checkbox disabled :true-value="1" :false-value="0" v-model="props.row.used">
                  </b-checkbox>
                </b-table-column>

                <b-table-column field="mode" label="Mode" v-slot="props">
                  <b-tag type="is-info is-light">
                    <b-icon class="mr-2" size="is-small" :icon="modeName(props.row.mode).icon"></b-icon>
                    {{ modeName(props.row.mode).name }}
                  </b-tag>
                </b-table-column>

                <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
                  <div class="buttons is-right">
                    <b-button icon-left="pencil" size="is-small" type="is-success" @click="formModal(props.row, the_package.partitions)"></b-button>
                  </div>
                </b-table-column>

                <template slot="empty">
                  <br>
                  <p class="has-text-centered">
                    <b-icon icon="cactus" size="is-large" />
                  </p>
                  <p class="has-text-centered">No outputs found for selected package</p>
                  <br>
                </template>

              </b-table>

            </b-tab-item>
          </b-tabs>
        </div>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child">


        </div>
      </div>

    </div>

  </section>

</template>

<script>

import CardComponent from '@/components/CardComponent'
import projects from "../store/modules/projects.store"
import OutputFormComponent from "@/components/project/OutputFormComponent";
import TitleBar from "@/components/TitleBar";



export default {
  name: 'ProjectOutputs',
  components: {TitleBar,  CardComponent, },
    data () {
        return {
          activeTab: 0,
          isLoading: true,
          isLoaded: false,
          packages: [],
          project_name: null,
          modeList: [
            {icon: 'toggle-switch', value: 0, name: 'Manual'},
            {icon: 'lock', value: 1, name: 'While Armed'},
            {icon: 'lock-alert', value: 2, name: 'When Triggered'},
            {icon: 'theme-light-dark', value: 3, name: 'Nightmode',},
            {icon: 'lock-clock', value: 4, name: 'Follow Schema'},

          ],
        }
    },
  computed: {
      titleStack() {
          return [
              this.project_name ? this.project_name : "...",
              'Outputs'
          ]
      },
      id () {
          return parseInt(this.$route.params.project_id)
      },



  },
    created() {
        if(!projects.isRegistered) {
            this.$store.registerModule('projects', projects)
          projects.isRegistered = true
        }
    },

    beforeMount() {
        this.fetchData()
    },

    methods: {
      modeName(id) {
        return this.modeList.filter(mode => mode.value === id)[0]
      },


        fetchData() {

          let formData = {
            id: this.id,
            params: "include=packages.alloutputs,packages.partitions"
          }

          this.$store.dispatch('projects/fetchProjectDetails', formData)
            .then(response => {
              this.project_name = response.data.data.name
              this.packages = response.data.data.packages
              this.isLoading = true;
              this.isLoaded = true;
            })

        },
        formModal(output, partitions) {
          let thisIns = this;
          this.$buefy.modal.open({
            parent: this,
            component: OutputFormComponent,
            props: {
              orig_output: output,
              partitions: partitions,
              project_id: this.id,

            },
            hasModalCard: true,
            trapFocus: true,
            ariaRole: 'dialog',
            width: 600,
            events: {
              refresh () {
                thisIns.fetchData();
              }
            }
          })
        },


    }
}
</script>

<style lang="scss">




</style>
